import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import { motion } from "framer-motion"; // Import framer-motion

export default function Services() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const services = [
    {
      title: "service_1_title",
      description: "service_1_text",
    },
    {
      title: "service_2_title",
      description: "service_2_text",
    },
    {
      title: "service_3_title",
      description: "service_3_text",
    },
    {
      title: "service_4_title",
      description: "service_4_text",
    },
    {
      title: "service_5_title",
      description: "service_5_text",
    },
    {
      title: "service_6_title",
      description: "service_6_text",
    },
  ];

  // Define alternating background colors based on the index
  const backgroundClasses = [
    "bg-[#3BB085] text-white",
    "bg-white text-gray-800",
  ]; // Modify bg-gray-100 to bg-white for white background

  return (
    <div className="pt-[160px] min-h-screen bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <div className="m-auto">
        <TextGenerateEffect
          words={Returntext(contentManagementData, "services_txt", lang)}
          className="mb-10 text-5xl font-bold w-[80dvw] m-auto "
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 m-auto w-[80vw] mt-6 mb-6">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              className={`service-card group relative rounded-sm shadow-lg p-2 overflow-hidden flex justify-center ${
                backgroundClasses[index % 2]
              }`}
              initial={{ opacity: 0, y: 50 }} // Initial state for animation
              animate={{ opacity: 1, y: 0 }} // Animate to this state
              transition={{ duration: 0.6, delay: index * 0.2 }} // Stagger animations
              whileHover={{ scale: 1.05 }} // Small hover effect
            >
              <div className="p-4 relative">
                <h3 className="text-xl font-semibold transition-all duration-300 ">
                  {Returntext(contentManagementData, service.title, lang)}
                </h3>
                <p
                  className="mt-2 text-m"
                  dangerouslySetInnerHTML={{
                    __html: Returntext(
                      contentManagementData,
                      service.description,
                      lang
                    ),
                  }}
                />
                {/* Conditionally set underline color based on background class */}
                <motion.div
                  className="absolute bottom-0 left-0 h-[4px] w-0 rounded-full"
                  style={{
                    backgroundColor:
                      backgroundClasses[index % 2] === "bg-white text-gray-800"
                        ? "#3BB085"
                        : "currentColor",
                  }} // Green underline for white cards, default for others
                  initial={{ width: 0, opacity: 0 }}
                  whileHover={{ width: "100%", opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
