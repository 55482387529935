import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { fetchNavbarData } from "../../helpers/fetcher";
import { NavbarItemType } from "../../helpers/types";

interface AboutUSProps {
  contentData?: any;
  lang?: string;
}

const ProductCategories: React.FC<AboutUSProps> = ({ lang }) => {
  const [navbarData, setNavbarData] = useState<NavbarItemType[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    NavbarItemType[]
  >([]);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: "start",
  });
  const [showBackButton, setShowBackButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  const fetchData = async () => {
    const data = await fetchNavbarData();
    const filtered = data.filter(
      (navItem: any) => navItem.name === "Products"
    )[0].children;

    // Add the "Show All" box dynamically to the data
    const showAllBox = {
      id: "show-all",
      name: lang === "en" ? "Show All" : "ყველას კატეგორია",
      name_ka: "ყველა კატეგორია",
      href: "",
      hrefIMG: "",
      children: filtered,
    };

    setNavbarData([showAllBox, ...filtered]);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit();

      const updateScrollButtons = () => {
        setShowBackButton(emblaApi.canScrollPrev());
        setShowNextButton(emblaApi.canScrollNext());
      };

      // Wait for Embla to be ready before updating the button states
      if (emblaApi.slideNodes().length > 0) {
        updateScrollButtons();
      }

      emblaApi.on("select", updateScrollButtons);

      return () => {
        emblaApi.off("select", updateScrollButtons); // Cleanup listener
      };
    }
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const handleCategoryClick = (category: NavbarItemType) => {
    if (category.children && category.children.length > 0) {
      setSelectedCategories((prev) => [...prev, category]);
    } else if (category.href) {
      window.location.href = category.href;
    }
  };

  const handleBack = () => {
    setSelectedCategories((prev) => prev.slice(0, -1));
  };

  const handleClose = () => {
    setSelectedCategories([]);
  };

  const currentCategories =
    selectedCategories.length > 0
      ? selectedCategories[selectedCategories.length - 1].children
      : navbarData;

  return (
    <div className="w-full mt-2 lg:px-5">
      <div>
        {/* Top Swiper Section */}
        <div className="relative w-full">
          <div className="overflow-hidden" ref={emblaRef}>
            <div className="flex">
              {navbarData.map((category, index) => (
                <div
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  className={`flex-shrink-0 h-[120px] mx-3 ${
                    category.id === "show-all"
                      ? "bg-[#3bb085] text-white  w-[150px]"
                      : "bg-gray-100  w-[120px]"
                  } rounded-lg shadow-md hover:shadow-lg transition-all duration-300 cursor-pointer flex items-center`}
                >
                  {category.id === "show-all" ? (
                    <div className="flex flex-col items-center justify-center w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white mb-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16m-7 6h7"
                        />
                      </svg>
                      <h3 className="text-base font-semibold text-white text-center">
                        {lang === "en" ? (
                          <>
                            All
                            <br />
                            Products
                          </>
                        ) : (
                          <>
                            ყველა
                            <br />
                            პროდუქტი
                          </>
                        )}
                      </h3>
                    </div>
                  ) : (
                    <>
                      <div className="w-full h-full rounded-l-lg overflow-hidden flex items-center p-3">
                        <img
                          src={category.hrefIMG}
                          alt={lang === "en" ? category.name : category.name_ka}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      {/* <div className="w-3/4 p-4">
                        <h3 className="text-base font-semibold text-gray-800">
                          {lang === "en" ? category.name : category.name_ka}
                        </h3>
                      </div> */}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Back Button */}
          {/* Back Button */}
          {showBackButton && (
            <button
              onClick={scrollPrev}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#3bb085] w-10 h-10 text-white rounded-full shadow-md z-10 transition-opacity duration-300"
            >
              &#8592;
            </button>
          )}

          {/* Forward Button */}
          {showNextButton && (
            <button
              onClick={scrollNext}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#3bb085] w-10 h-10 text-white rounded-full shadow-md z-10 transition-opacity duration-300"
            >
              &#8594;
            </button>
          )}
        </div>

        {/* Bottom Dynamic Section */}
        {selectedCategories.length > 0 && (
          <div
            className="absolute p-6 mt-8 rounded-lg shadow-lg bg-white w-full sm:w-[95%] max-w-[100%] z-100 pb-10"
            style={{ overflowY: "scroll", maxHeight: "80vh" }}
          >
            <div className="flex justify-between items-center mb-4 ">
              <button
                onClick={handleBack}
                className="text-sm text-gray-500 hover:text-gray-700 flex items-center"
              >
                &#8592; {lang === "en" ? "Back" : "უკან"}
              </button>
              <button
                onClick={handleClose}
                className="w-7 h-7 flex items-center justify-center rounded-full bg-[#2B2D37] shadow-md transition-colors duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-5">
              {lang === "en"
                ? selectedCategories[selectedCategories.length - 1].name
                : selectedCategories[selectedCategories.length - 1].name_ka}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
              {currentCategories?.map((subCategory, idx) => (
                <div
                  key={idx}
                  onClick={() => handleCategoryClick(subCategory)}
                  className="flex items-center justify-between p-2 bg-gray-100 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-300"
                >
                  <div>
                    <h4 className="text-sm font-medium text-gray-800">
                      {lang === "en" ? subCategory.name : subCategory.name_ka}
                    </h4>
                  </div>
                  <img
                    src={subCategory.hrefIMG}
                    alt={lang === "en" ? subCategory.name : subCategory.name_ka}
                    className="w-16 h-16 object-cover rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCategories;
