import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  fetchFilters,
  fetchNavbarDataById,
  fetchProducts,
  searchProduct,
} from "../helpers/fetcher";
import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import {
  CardBody,
  CardContainer,
  CardItem,
} from "../components/animatedCard/AnimatedCard";
import { CircularPagination } from "../components/circularPagination/circularPagination";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import notFoundIcon from "../assets/images/noItemFound.jpg";
import imgOther from "../assets/images/otherImage.png";
import CustomSelect from "../components/CustomSelect/CustomSelect";

interface ParamsType {
  [key: string]: string | undefined;
  categoryId?: string;
  categoryName?: string;
}

interface SelectedFilters {
  [key: string]: string;
}

interface FilterData {
  prm_1: string[];
  prm_2: string[];
  prm_3: string[];
  prm_4: string[];
  prm_5: string[];
  prm_6: string[];
  prm_7: string[];
}

export default function Products() {
  const { categoryId, categoryName } = useParams<ParamsType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [productData, setProductData] = useState<any>(null);
  const [navbarData, setNavbarData] = useState<any>(null);
  const [filterData, setFilterData] = useState<FilterData | null>(null);
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const initialSelectedFilters: SelectedFilters = {
    prm_1: "",
    prm_2: "",
    prm_3: "",
    prm_4: "",
    prm_5: "",
    prm_6: "",
    prm_7: "",
  };

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    initialSelectedFilters
  );

  const fetchData = useCallback(
    async (page: number) => {
      try {
        setLoading(true);
        setShowNotFound(false);

        let products;
        if (categoryName === "searchProduct") {
          products = await searchProduct(page, 1, categoryId!);
        } else if (categoryName === "search") {
          products = await searchProduct(page, 12, categoryId!);
        } else {
          products = await fetchProducts(
            categoryId!,
            page,
            12,
            selectedFilters
          );

          const navbar = await fetchNavbarDataById(categoryId!);
          const filters = await fetchFilters(categoryId!, selectedFilters);
          setNavbarData(navbar);

          setFilterData({
            prm_1: filters.prm_1 || [],
            prm_2: filters.prm_2 || [],
            prm_3: filters.prm_3 || [],
            prm_4: filters.prm_4 || [],
            prm_5: filters.prm_5 || [],
            prm_6: filters.prm_6 || [],
            prm_7: filters.prm_7 || [],
          });
        }

        setProductData(products);

        if (products?.items?.length === 0) {
          setTimeout(() => {
            setShowNotFound(true);
          }, 1500);
        }

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
        setTimeout(() => {
          setShowNotFound(true);
        }, 1500);
      }
    },
    [categoryId, categoryName, selectedFilters]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, fetchData]);

  const handleFilterChange = (filterKey: string, value: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setSelectedFilters(initialSelectedFilters);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line
  }, [selectedFilters]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [categoryId, currentPage, categoryName]);

  const [currentDropdown, setCurrentDropdown] = useState(null);

  const toggleDropdown = (id: any) => {
    setCurrentDropdown(currentDropdown === id ? null : id);
  };
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const triggerPoint = 160; // Adjust this based on where the filter should become fixed
      setIsFixed(scrollY > triggerPoint);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="min-h-screen  bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      {loading ? (
        <div className="pt-[160px] w-[95vw] m-auto">
          <div className="flex justify-center items-center h-1/2">
            <span className="loading loading-ring loading-xl w-[30%] bg-[#39B085]"></span>
          </div>
        </div>
      ) : (
        <div className="pt-[160px] w-[95vw] m-auto ">
          <TextGenerateEffect
            words={lang === "ka" ? navbarData?.title_ka : navbarData?.title_en}
            className="mb-10 text-4xl ml-[7.5vw]"
          />
          <div
            className={`flex gap-4 mb-8 ml-[7.5vw] ${
              isFixed ? "md:fixed md:top-[75px] z-10 md:bg-white " : "relative"
            }`}
            style={isFixed ? { boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" } : {}}
          >
            <div
              className="grid grid-cols-2 md:grid-cols-1 md:flex flex-wrap gap-4 top-[20px] w-full z-10"
              style={{
                padding: "10px",
              }}
            >
              {Object.keys(selectedFilters).map((filterKey) => {
                const filterOptions =
                  filterData?.[filterKey as keyof FilterData];
                const placeholderText =
                  navbarData?.details?.[
                    `${filterKey}_${lang === "ka" ? "ge" : "en"}`
                  ];

                if (
                  Array.isArray(filterOptions) &&
                  filterOptions.length > 0 &&
                  placeholderText
                ) {
                  return (
                    <CustomSelect
                      key={filterKey}
                      options={filterOptions.map((value: any) => ({
                        value: typeof value === "object" ? value.id : value,
                        label: typeof value === "object" ? value.name : value,
                      }))}
                      selectedValue={selectedFilters[filterKey]}
                      onChange={(value) => handleFilterChange(filterKey, value)}
                      placeholder={
                        placeholderText || (lang === "ka" ? "ფილტრი" : "Filter")
                      }
                      lang={lang}
                    />
                  );
                }

                return null;
              })}

              {navbarData?.details ? (
                <div className="md:flex items-center justify-center  hidden">
                  <button
                    onClick={handleClearFilters}
                    className="px-3 py-1 flex items-center gap-2 bg-[#2B2D37] text-white rounded-md mt-4 sm:mt-0 h-9"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-4 h-4 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[5vw] w-[80vw] m-auto">
            <div className="w-full">
              {loading ? (
                <div className="flex justify-center items-center h-1/2">
                  <span className="loading loading-ring loading-xl w-[30%] bg-[#39B085]"></span>
                </div>
              ) : productData?.items?.length > 0 ? (
                <div className="flex flex-wrap justify-start items-center gap-3 w-full ">
                  {productData.items.map((product: any, key: any) => (
                    <CardContainer className="inter-var  " key={key}>
                      <CardBody className="relative max_width group/card hover:shadow-xl hover:shadow-emerald-500/[0.1] border-gray-200 h-auto rounded-xl p-4 border flex flex-col justify-betwee">
                        <div>
                          <CardItem
                            translateZ="50"
                            className="text-l font-bold text-neutral-600"
                          >
                            {lang === "ka"
                              ? product?.name_ka
                              : product?.name_en}
                          </CardItem>

                          <CardItem
                            as="p"
                            translateZ="60"
                            className="text-neutral-500 text-sm max-w-sm mt-2"
                          >
                            {lang === "ka"
                              ? product?.description_ka
                              : product?.description_en}
                          </CardItem>
                          <CardItem
                            as="p"
                            translateZ="60"
                            className="text-neutral-500 text-sm max-w-sm mt-2"
                          >
                            {product?.code}
                          </CardItem>
                          <CardItem
                            translateZ="100"
                            className="w-full mt-4 flex justify-center"
                          >
                            <img
                              src={`https://montage.ge/img/${product?.photo}`}
                              alt={product?.name_en}
                              className="w-auto h-[200px] object-cover"
                              onError={(e) => {
                                e.currentTarget.src = imgOther;
                              }}
                            />
                          </CardItem>
                        </div>

                        <CardItem className="flex justify-between items-center w-90 py-4 ">
                          <div className="flex items-center gap-2 text-green-600 font-semibold">
                            {product?.stock ? (
                              <div className="flex items-center gap-2 text-green-600 font-semibold">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  className="w-5 h-5"
                                >
                                  <path d="M9 16.2l-3.5-3.5 1.4-1.4L9 13.4l7.1-7.1 1.4 1.4z" />
                                </svg>
                                <span>
                                  {lang === "ka" ? "მარაგშია" : "In stock"}
                                </span>
                              </div>
                            ) : (
                              <div className="text-red-600 font-semibold">
                                {lang === "ka" ? "შეკვეთით" : "BY ORDER"}
                              </div>
                            )}
                          </div>
                          {product?.price !== 0 && (
                            <div className="text-lg font-bold text-gray-800">
                              ₾ {product?.price}
                            </div>
                          )}
                        </CardItem>

                        <CardItem className="rounded-xl text-sm bg-black font-bold text-[#2B2D37] cursor-pointer border-2 border-[#e5e7eb] text-center w-full">
                          {product?.pdf ||
                          product?.pdf1 ||
                          product?.pdf2 ||
                          product?.pdf3 ? (
                            <div className="relative inline-block text-left w-full">
                              <button
                                id="dropdownDefaultButton"
                                onClick={() => toggleDropdown(product.id)}
                                className="bg-white text-gray-700 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center w-full justify-between border border-gray-300"
                              >
                                {Returntext(
                                  contentManagementData,
                                  "data_sheet",
                                  lang
                                )}
                                <svg
                                  className={`w-2.5 h-2.5 ml-3 transition-transform duration-200 ${
                                    currentDropdown === product.id
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 6"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 1l4 4 4-4"
                                  />
                                </svg>
                              </button>

                              {currentDropdown === product.id && (
                                <div className="absolute bottom-full mb-2 bg-gray-100 divide-y divide-gray-100 rounded-lg shadow w-full z-50">
                                  <ul className="py-2 text-sm text-gray-700">
                                    {[
                                      {
                                        label: Returntext(
                                          contentManagementData,
                                          "pdf",
                                          lang
                                        ),
                                        url: product.pdf,
                                      },
                                      {
                                        label: Returntext(
                                          contentManagementData,
                                          "pdf1",
                                          lang
                                        ),
                                        url: product.pdf1,
                                      },
                                      {
                                        label: Returntext(
                                          contentManagementData,
                                          "pdf2",
                                          lang
                                        ),
                                        url: product.pdf2,
                                      },
                                      {
                                        label: Returntext(
                                          contentManagementData,
                                          "pdf3",
                                          lang
                                        ),
                                        url: product.pdf3,
                                      },
                                    ]
                                      .filter((file) => file.url)
                                      .map((file, index) => (
                                        <a
                                          href={`https://montage.ge/img/${file.url}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={() =>
                                            setCurrentDropdown(null)
                                          }
                                          className="block px-4 py-2 text-gray-700 hover:bg-gray-200 w-[100%]"
                                          key={index}
                                        >
                                          <li>{file.label}</li>
                                        </a>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          ) : (
                            // "No PDF available" styled similarly to the dropdown button
                            <button
                              disabled
                              className="bg-white text-gray-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center w-full justify-between bordercursor-not-allowed"
                            >
                              {Returntext(
                                contentManagementData,
                                "no_pdf_available",
                                lang
                              )}
                            </button>
                          )}
                        </CardItem>
                      </CardBody>
                    </CardContainer>
                  ))}
                </div>
              ) : (
                showNotFound && (
                  <div className="flex justify-center items-center mt-10">
                    <img
                      src={notFoundIcon}
                      alt="Not found"
                      className="w-full lg:w-[60%] object-cover"
                    />
                  </div>
                )
              )}
            </div>
          </div>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-4">
            {productData?.meta?.totalPages > 1 && (
              <CircularPagination
                activePage={currentPage}
                setActivePage={setCurrentPage}
                totalPages={productData?.meta?.totalPages}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
