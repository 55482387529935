import React from "react";
import { Returntext, useContent } from "../../helpers/contLang";
import ProductCategories from "../productCategories/ProductCategories";
import arrow from "../../assets/icons/arrow.png";
import mainIMG from "../../assets/images/heroIMG.png";
interface HeroType {
  contentData?: any;
  lang?: string;
}

const Hero: React.FC<HeroType> = ({ contentData, lang }) => {
  const { contentManagementData } = useContent();

  const services = [
    { title: "service_1_title" },
    { title: "service_2_title" },
    { title: "service_3_title" },
    { title: "service_4_title" },
    { title: "service_5_title" },
    { title: "service_6_title" },
  ];

  return (
    <div className="relative w-full min-h-[calc(100vh+120px)] pt-[150px] bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px] overflow-hidden">
      <div className="relative z-100 flex flex-col items-center font-sans px-4 sm:px-6 md:px-8">
        <ProductCategories contentData={contentData} lang={lang} />

        <div className="flex flex-col-reverse lg:flex-row items-center justify-between w-full lg:px-5  space-y-4 lg:space-y-0">
          {/* Text Section */}
          <div className="flex flex-col w-full items-center lg:items-start text-center lg:text-left lg:mb-0 lg:w-1/2 sm:space-y-10 space-y-3">
            <h2 className=" nav_font_ka  text-[#2B2D37]   text-[24px] sm:text-[16px] md:text-[20px] lg:text-[37px] font-bold h2_font_ka max-w-full md:max-w-[600px] leading-tight">
              {Returntext(contentData, "MG_slogan", lang)} !
            </h2>
            <div className="flex flex-col">
              <img src={arrow} alt="Arrow Icon" className="hidden lg:block" />
              <h2 className="nav_font_ka !mt-0  text-[20px] sm:text-[12px] md:text-[18px] lg:text-[25px] font-bold h2_font_ka max-w-full md:max-w-[600px] text-[#3bb085] self-end text-right mr-4">
                16 {Returntext(contentData, "experience", lang)}
              </h2>
            </div>
            <div className="w-full">
              <p className="text-[14px]  nav_font_ka sm:text-[16px] md:text-[18px] lg:text-[20px] font-light text-[#2B2D37]  leading-relaxed">
                {lang === "ka"
                  ? "ვასრულებთ სხვადასხვა ტიპის ელექტრო სამონტაჟო სამუშაოებს. გთავაზობთ სრულ სერვისს: "
                  : "We perform various types of electrical installation works. We offer a full range of services: "}
                {services
                  .map((service, index) =>
                    Returntext(
                      contentManagementData,
                      service.title,
                      lang
                    ).toLowerCase()
                  )
                  .filter(Boolean)
                  .join(", ")}
                .
              </p>
            </div>
          </div>

          {/* Image Section */}
          <div className="relative w-full lg:w-1/2 h-[300px] sm:h-[400px] md:h-[500px] flex items-center justify-end -z-10">
            <div className="relative w-[90%] h-[90%] sm:w-[80%] sm:h-[80%] lg:w-[100%] lg:h-[80%] overflow-hidden flex items-center justify-end">
              <img
                src={mainIMG}
                alt="Feature 2"
                className="w-[90%] h-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
